import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Hero, BecomeMember } from '../components/Sections'
import { getAssetUrl } from '../services/cdn'
import { Container, Row, Col } from 'reactstrap'
import { Container as BlockContainer, Features } from '../components/Blocks'
import LoanCalculator from '../components/Forms/LoanCalculator'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/Koopinoy-Savings-Loans Batangas.jpg' mobileSrc='app/KooPinoy_Cover_Mobile.jpg' fullMobile height={600} x={10} y={100} />
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Build your future. Live for better life.</h1>
              <div className="text-justify">
                <p>Here at KooPinoy, we offer a wide range of custom-made financial support and assistance to fulfill your financial requirements. Based in the City of Batangas, under the umbrella of Soro-Soro Ibaba Development Cooperative (SIDC) our main aspiration is to provide easy, hassle-free, customizeable loan  service, where our members' needs are put first. We accept time deposit, regular savings deposits, and savings for young ones. In return, comparably high interest rates were offered to account holders, both regular and associate members. It also extends loans to members with a competitive interest rate. Not a fan of owing a money? Not a problem - Life is easier with us. Build your future. Live for better life.</p>
              </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Features of Koopinoy</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Tax-Exempt.png" iconClass="fa fa-circle fa-3x" title="TAX-EXEMPTED">All financial transactions are absolutely tax-free.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Membership-Based.png" iconClass="fa fa-stop-circle-o fa-3x" title="MEMBERSHIP-BASED">Your not just a customer but a co-owner.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Inter-Branch.png" iconClass="fa fa-stop-circle fa-3x" title="INTERBRANCH TRANSACTIONS">can transact at any KooPinoy branches at no fees.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/No-Pretermination-Fee.png" iconClass="fa fa-circle-o fa-3x" title="NO PRE-TERMINATION FEE">No fees collected to a borrower by paying out the balance before the loan matures.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/No-Hidden-Charge.png" iconClass="fa fa-circle fa-3x" title="NO HIDDEN CHARGES">All minimal fees are explained and disclosed before the loan application.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Christmas-Gift.png" iconClass="fa fa-stop-circle-o fa-3x" title="CHRISTMAS GIFT">Souvenir items for depositors and borrowers every December</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Diminishing-Balance.png" iconClass="fa fa-stop-circle fa-3x" title="DIMINISHING BALANCE">Interest is computed based on outstanding balance.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Patronage-Refund.png" iconClass="fa fa-circle-o fa-3x" title="PATRONAGE REFUND">Portion of interest paid will be given to borrowers and fees paid on insurance products.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Trip-Incentives.png" iconClass="fa fa-circle fa-3x" title="TRIP INCENTIVES">Annual incentive for top borrower with good account management and top 2 depositors.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/High-Interest-Rate.png" iconClass="fa fa-stop-circle-o fa-3x" title="HIGH INTEREST RATE ON DEPOSITS">No withholding tax dedcuted for the interest earned from your deposits.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Allied-Financial-Services.png" iconClass="fa fa-stop-circle fa-3x" title="ALLIED FINANCIALS SERVICES">You can load, pay your bills and transact both domestic and international remittance while waiting.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features src="koopinoy/Easy-and-convenient.png" iconClass="fa fa-circle-o fa-3x" title="EASY AND CONVENIENT">We bring affordable financial services and technical assistance in every community.</Features>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <LoanCalculator />
    <BlockContainer padding={5} dark id="features">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
              <h2 className="pb-4 text-dark">Unlock Koopinoy benefits with membership</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col lg="4" md="6" className="text-md-right text-center">
              <h4 className="text-primary mb-5">HIGH PATRONAGE REFUND</h4>
              <h4 className="text-primary mb-5">HIGH YIELD RETURN OF INTEREST</h4>
              <h4 className="text-primary mb-5">LOYALTY REWARD PROGRAM</h4>
              <h4 className="text-primary">MORTUARY BENEFIT</h4>
          </Col>
          <Col md="4" className="my-3 d-none d-lg-block">
              <img className="img-fluid d-block" alt="KOOPINOY" src={getAssetUrl('app/Koopinoy_Logo_Square.jpg')} />
          </Col>
          <Col lg="4" md="6" className="align-self-center text-md-left text-center">
              <h4 className="text-primary mb-5">SCHOLARSHIP GRANTS</h4>
              <h4 className="text-primary mb-5">FINANCIAL LITERACY SEMINAR</h4>
              <h4 className="text-primary mb-5">YEARLY INCENTIVE</h4>
          </Col>
          </Row>
      </Container>
  </BlockContainer>
  <BecomeMember />
  </Layout>
)

export default IndexPage
